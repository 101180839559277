import { useEffect, useState } from "react";
import { t } from "@/i18n-js/instance";
import { useCurrentUser } from "@circle-react/contexts";
import { Button } from "@circle-react-uikit/Button";
import { Form } from "@circle-react-uikit/Form";
import { Input } from "@circle-react-uikit/Input";
import { Modal } from "@circle-react-uikit/ModalV2";
import { taxIdTypes } from "../../../Paywalls/Checkout/CheckoutForm/CheckoutFormTaxIdField/taxIdTypes";

const i18nRoot = "pricing_plans.upgrade_modal";

export const TaxIdForm = ({ hideModal, onSubmitHandler }: any) => {
  const brTaxIdTypes = taxIdTypes.filter(
    taxIdType => taxIdType.countryCode === "BR",
  );

  const handleSubmit = (taxData: any) => {
    onSubmitHandler(taxData);
  };

  const [taxIdType, setTaxIdType] = useState("");
  const [placeholder, setPlaceholder] = useState(brTaxIdTypes[0].example);

  useEffect(() => {
    const selectedTaxIdType = brTaxIdTypes.find(
      item => item.enum === taxIdType,
    );
    if (selectedTaxIdType) {
      setPlaceholder(selectedTaxIdType.example);
    }
  }, [taxIdType, brTaxIdTypes]);

  const currentUser = useCurrentUser();

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <div className="text-center text-xl">
            {t([i18nRoot, "tax_id.title"])}
          </div>
        </Modal.Title>
        <Modal.HeaderActions>
          <Modal.CloseButton onClick={hideModal} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <p className="">{t([i18nRoot, "tax_id.email_description"])}</p>
          <p className="text-dark mb-2 mt-5 flex font-semibold">
            {t([i18nRoot, "tax_id.form.email.label"])}
          </p>
          <Input
            className="form-control"
            name="input"
            prefix=""
            suffix=""
            value={currentUser.email}
            variant="text"
            disabled
          />
          <p className="my-2 text-sm">
            {t([i18nRoot, "tax_id.form.email.description"])}
          </p>
          <p className="my-2 font-semibold">
            {t([i18nRoot, "tax_id.form.type.label"])}
          </p>
          <Form.SelectNative
            name="tax_id_type"
            defaultValue=""
            onChange={e => setTaxIdType(e.target.value)}
            options={brTaxIdTypes.map(taxIdType => ({
              value: taxIdType.enum,
              label: taxIdType.label,
            }))}
            prompt={t([i18nRoot, "tax_id.form.type.prompt"])}
            isPromptDisabled={false}
            rules={{
              required: t(`${i18nRoot}.form.errors.type_required`),
            }}
          />

          <Form.Item
            name="tax_id_value"
            hideDescription
            hideLabel
            hideBorder
            placeholder={placeholder}
            rules={{
              required: t(`${i18nRoot}.tax_id.form.errors.number_required`),
            }}
          >
            <Form.Input
              placeholder={t([i18nRoot, "tax_id.number_placeholder"])}
            />
          </Form.Item>

          <Form.Item
            name="ccm"
            label={t([i18nRoot, "tax_id.form.ccm.label"])}
            hideDescription
            hideBorder
            className="my-4"
            rules={{}}
          >
            <Form.Input
              placeholder={t([i18nRoot, "tax_id.form.ccm.placeholder"])}
            />
          </Form.Item>

          <Form.Item hideDescription hideBorder>
            <Button variant="primary" type="submit" className="w-full">
              {t([i18nRoot, "actions.submit_and_go_to_checkout"])}
            </Button>
          </Form.Item>
        </Form>
      </Modal.Body>
    </>
  );
};

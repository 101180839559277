import { useHistory } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { usePunditUserContext } from "@circle-react/contexts";
import { getNextTier, planTiers } from "@circle-react/helpers/planHelpers";
import { memberSettingsPaths } from "@circle-react/helpers/urlHelpers";
import { usePlanStatus } from "@circle-react/hooks/usePlanStatus";
import { FilterChip } from "@circle-react-shared/Filter/FilterChip";
import { useUpgradePlanModal } from "@circle-react-shared/UpgradePlan";
import { UPGRADE_FLOW_SOURCES } from "@circle-react-shared/UpgradePlan/constants";
import { useFetchPlan } from "@circle-react-shared/UpgradePlan/hooks/useFetchPlan";
import { Loader } from "@circle-react-uikit/Loader";

const BUTTON_LABEL_KEYS = [
  "profile_fields.upgrade_flow.button_labels.add_birthday",
  "profile_fields.upgrade_flow.button_labels.add_profession",
  "profile_fields.upgrade_flow.button_labels.add_skills",
];

const getMinimumRequiredPlanTier = (source?: string) => {
  if (source === UPGRADE_FLOW_SOURCES.PROFILE_FIELDS_ANALYTICS_FILTER_CHIP) {
    return planTiers.enterprise;
  }
  return planTiers.business;
};

const getSubheading = (nextTier?: string, source?: string) => {
  if (
    nextTier !== planTiers.enterprise &&
    source === UPGRADE_FLOW_SOURCES.PROFILE_FIELDS_ANALYTICS_FILTER_CHIP
  ) {
    return "pricing_plans.upgrade_modal.subheadings.analytics_filters_from_profile_fields_below_business";
  }
  return "pricing_plans.upgrade_modal.subheadings.analytics_filters";
};

const getSuccessButtonLabel = (source?: string) => {
  if (source === UPGRADE_FLOW_SOURCES.PROFILE_FIELDS_ANALYTICS_FILTER_CHIP) {
    return "pricing_plans.upgrade_modal.actions.go_to_analytics";
  }
  return "profile_fields.upgrade_flow.button_labels.go_to_profile_fields";
};

export interface UpgradeFlowProps {
  source?: string;
}

export const UpgradeFlow = ({ source }: UpgradeFlowProps) => {
  const upgradePlanModal = useUpgradePlanModal();
  const history = useHistory();
  const { changePlanModalParams } = usePlanStatus();
  const minimumRequiredPlanTier = getMinimumRequiredPlanTier(source);
  const planTierOrId = changePlanModalParams(minimumRequiredPlanTier);
  const { planTier, planId } = planTierOrId;
  const { refetch: refetchPundit, currentCommunity } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity;
  const nextTier = getNextTier(currentPlanTier) || undefined;

  const { plan, isPlanLoading } = useFetchPlan({
    planTier,
    planId,
  });

  const subheading = getSubheading(nextTier, source);
  const successButtonLabel = getSuccessButtonLabel(source);

  const onChangePlanSuccess = async () => {
    if (source == UPGRADE_FLOW_SOURCES.PROFILE_FIELDS_ANALYTICS_FILTER_CHIP) {
      await refetchPundit();
    } else {
      history.push(memberSettingsPaths.profileFieldList());
    }
    await upgradePlanModal.hide();
  };

  const handleClick = async () => {
    await upgradePlanModal.show({
      planId: plan.id,
      subheading: t(subheading),
      onSuccess: onChangePlanSuccess,
      successButtonLabel: t(successButtonLabel),
      source,
      showPlanDetails: true,
    });
  };

  if (isPlanLoading) {
    return <Loader />;
  }

  return (
    <div className="grid grid-cols-1 gap-y-4 py-1">
      {BUTTON_LABEL_KEYS.map(key => (
        <button
          key={key}
          type="button"
          className="cursor-pointer"
          onClick={handleClick}
        >
          <FilterChip>{t(key)}</FilterChip>
        </button>
      ))}
    </div>
  );
};

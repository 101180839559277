import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { planSettingsPath } from "@/react/helpers/urlHelpers";
import { usePunditUserContext } from "@circle-react/contexts";
import { getNextTier, isPlanBasic } from "@circle-react/helpers/planHelpers";
import { CurrencySelector } from "@circle-react-shared/Billing/CurrencySelector";
import { Icon } from "@circle-react-shared/Icon";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/ModalV2";
import { Typography } from "@circle-react-uikit/Typography";
import { LineItem } from "./LineItem";
import { useUpgradePlanContext } from "./UpgradePlanContext";

const i18nRoot = "pricing_plans.upgrade_modal";

export const PlanDetails = ({
  subheading = "",
  hideModal,
  onSubmitHandler,
}) => {
  const {
    billingCurrency,
    setBillingCurrency,
    plan,
    marketingHubPlan,
    shouldShowCurrencySelection,
    isMarketingHubRequired,
    isMarketingHubOnlyUpgrade,
    isDoubleUpgrade,
  } = useUpgradePlanContext();

  const { currentCommunity } = usePunditUserContext();
  const { current_plan_tier: currentPlanTier } = currentCommunity || {};

  const learnMoreRedirect = () => (window.location.href = planSettingsPath());

  const nextTier = getNextTier(currentPlanTier);

  let everythingInCopy;
  if (isMarketingHubOnlyUpgrade) {
    everythingInCopy = "marketing_hub_features_title";
  } else if (nextTier === plan?.tier) {
    everythingInCopy = `everything_in_${plan.tier}`;
  } else {
    everythingInCopy = "everything_in_your_plan_plus";
  }

  const shouldShowFeatureList = !isPlanBasic(plan?.tier);
  const featuresToDisplay =
    plan?.main_features || marketingHubPlan?.main_features || [];

  const isPlanOnOffer = plan?.plan_coupon?.formatted_discount_percentage;
  const displayIntervalText =
    plan?.plan_coupon?.interval_abbreviation ||
    plan?.interval_abbreviation ||
    "";

  const amountWithCurrency = isPlanOnOffer
    ? plan?.plan_coupon?.discounted_monthly_price_with_currency
    : plan?.amount_without_currency || "";

  const displayName =
    plan?.display_name ||
    marketingHubPlan?.display_name ||
    t([i18nRoot, "default_plan_name"]);

  const onClickHandler = () => {
    onSubmitHandler();
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {t([i18nRoot, "title"], { plan: displayName })}
        </Modal.Title>

        <Modal.HeaderActions>
          <Modal.CloseButton onClick={hideModal} />
        </Modal.HeaderActions>
      </Modal.Header>
      <Modal.Body>
        {subheading && (
          <Typography.BodyMd weight="" color="text-default">
            {isMarketingHubOnlyUpgrade
              ? t("pricing_plans.upgrade_modal.marketing_hub_value_prop_title")
              : subheading}
          </Typography.BodyMd>
        )}

        <div className="border-primary mt-6 rounded-lg border p-5">
          {plan && (
            <LineItem
              displayName={t([i18nRoot, "plan_name"], {
                plan: plan.display_name,
              })}
              discountPercentage={
                plan?.plan_coupon?.formatted_discount_percentage || null
              }
              amountWithCurrency={amountWithCurrency}
              displayIntervalText={displayIntervalText}
              billingCurrency={billingCurrency}
              setBillingCurrency={setBillingCurrency}
              description={t([
                i18nRoot,
                `${plan.tier || "default"}_description`,
              ])}
              isFirstLineItem={isMarketingHubRequired}
              featuresToDisplay={featuresToDisplay}
              isDoubleUpgrade={isDoubleUpgrade}
            />
          )}

          {isMarketingHubRequired && marketingHubPlan && (
            <LineItem
              displayName={t([i18nRoot, "marketing_hub_plan_name"], {
                plan: marketingHubPlan.display_name,
              })}
              amountWithCurrency={marketingHubPlan.amount_without_currency}
              displayIntervalText={marketingHubPlan.interval_abbreviation}
              shouldShowCurrencySelection={false}
              description={t([i18nRoot, "marketing_hub_description"])}
              mhMaximumTierUsage={marketingHubPlan.current_tier_up_to}
              mhCurrentUsage={marketingHubPlan.contact_count}
              isDoubleUpgrade={isDoubleUpgrade}
              isFreeTier={marketingHubPlan.is_free_tier}
            />
          )}
        </div>

        {shouldShowCurrencySelection && (
          <CurrencySelector
            billingCurrency={billingCurrency}
            onCurrencyChange={setBillingCurrency}
            className="bg-tertiary mt-2"
            minimal
          />
        )}

        {!isDoubleUpgrade && shouldShowFeatureList && (
          <>
            <div className="mt-6">
              <Typography.LabelLg weight="semibold">
                {t([i18nRoot, everythingInCopy])}
              </Typography.LabelLg>
            </div>

            {featuresToDisplay.length > 0 && (
              <ul className="mt-6 flex list-none flex-col gap-5">
                {featuresToDisplay.map((feature, index) => {
                  const itemKey = `feature-item-${index}`;
                  return (
                    <li key={itemKey} className="flex items-center gap-3">
                      <Icon
                        type="20-checkmark-blue"
                        size={20}
                        className="!h-5 !w-5"
                      />
                      <Typography.LabelMd weight="medium">
                        {feature}
                      </Typography.LabelMd>
                    </li>
                  );
                })}
              </ul>
            )}

            {!isMarketingHubOnlyUpgrade && (
              <div className="mt-5">
                <Link onClick={learnMoreRedirect}>
                  <Typography.LabelMd weight="medium" color="text-default">
                    {t([i18nRoot, "learn_more"])}
                  </Typography.LabelMd>
                </Link>
              </div>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="mt-5">
        <Button variant="circle" large full onClick={onClickHandler}>
          {t([i18nRoot, "actions.upgrade"], { plan: displayName })}
        </Button>
      </Modal.Footer>
    </>
  );
};

PlanDetails.propTypes = {
  subheading: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
};

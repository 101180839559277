import type { ChangeEvent } from "react";
import { useCallback, useEffect, useState } from "react";
import type { Basic } from "unsplash-js/src/methods/photos/types";
import { t } from "@/i18n-js/instance";
import { ASPECT_RATIO } from "@circle-react/components/constants";
import { ModalWrapper } from "@circle-react-shared/TrixEditor/InlineModalSelector";
import { Uploader } from "../Uploader";
import { ImageCropper } from "./Cropper";
import type { Area, Point } from "./Cropper";
import { FileUploadMethods } from "./FileUploadMethods";
import { aspectRatioLabels } from "./constants";
import {
  checkIsImageFile,
  createRandomName,
  downloadPhoto,
  getCroppedImage,
  isImageURL,
  readURL,
  searchUnsplash,
} from "./functions";

export interface ImageEditorModalWithCropProps {
  aspectRatio?: number;
  attachImage?: ((itemUrl: string, signedId: string) => void) | null;
  closeModal: () => void;
  customAspectRatioHelpText?: string;
  formClass?: string;
  hasFile?: boolean;
  hideEmbedUrl?: boolean;
  hideUnsplash?: boolean;
  inputFieldName?: string;
  isCropEnabled?: boolean;
  saveImage?: ((signedId: string) => void) | null;
  setHasFile: (hasFile: boolean) => void;
  showModal?: boolean;
  title?: string;
  uploadProgress: (progress: number, fileName: string) => void;
}

export const ImageEditorModalWithCrop = ({
  aspectRatio = ASPECT_RATIO.DEFAULT,
  attachImage = null,
  closeModal,
  customAspectRatioHelpText = "",
  formClass = "",
  hasFile = false,
  hideEmbedUrl = false,
  hideUnsplash = false,
  inputFieldName = "",
  isCropEnabled = false,
  saveImage = null,
  setHasFile,
  showModal = false,
  title = "",
  uploadProgress,
}: ImageEditorModalWithCropProps) => {
  const [fileList, setFileList] = useState<FileList>();
  const [stringFile, setStringFile] = useState<string>();
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [cropArea, setCropArea] = useState<Area | null>(null);
  const [zoom, setZoom] = useState<number>(1);
  const [embedUrl, setEmbedUrl] = useState<string>("");
  const [unsplashResults, setUnsplashResults] = useState<Basic[]>([]);
  const [imageError, setImageError] = useState<string>("");

  const shouldShowImageCropper = hasFile && isCropEnabled;

  const aspectRatioHelpText = customAspectRatioHelpText
    ? customAspectRatioHelpText
    : aspectRatioLabels[aspectRatio] || "";

  const setUnsplash = async (query: string) => {
    const results = await searchUnsplash(query);
    setUnsplashResults(results);
  };

  useEffect(() => {
    void setUnsplash("wallpapers");
  }, []);

  const addUnsplash = (item: Basic) => {
    setStringFile(item.urls.regular);
    setHasFile(true);
    void downloadPhoto(item);
  };

  const insertFiles = async (files: FileList | null) => {
    if (!files) return;
    setFileList(files);

    const filesArray = Array.from(files);

    for (const file of filesArray) {
      const isImageFile = checkIsImageFile(file["type"]);
      if (isImageFile) {
        setImageError("");
        const parsedFile = await readURL(file);
        setHasFile(true);
        setStringFile(parsedFile);
      } else {
        setImageError(t("image_upload.invalid_file_type_error"));
      }
    }
  };

  const onChangeEmbedUrl = (event: ChangeEvent<HTMLInputElement>) => {
    setImageError("");
    setEmbedUrl(event.target.value);
  };

  const addEmbed = async () => {
    try {
      await isImageURL(embedUrl);
      setStringFile(embedUrl);
      setHasFile(true);
    } catch (error: unknown) {
      if (error instanceof Error) {
        setImageError(error.message);
      }
    }
  };

  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    setCropArea(croppedAreaPixels);
  }, []);

  const saveCroppedImage = useCallback(async () => {
    try {
      if (!stringFile || !cropArea) {
        throw new Error(t("image_upload.file_cropped_area_error"));
      }

      const fileArray = fileList ? Array.from(fileList) : [];
      const firstFile = fileArray ? fileArray[0] : null;

      const fileName = firstFile ? firstFile.name : createRandomName();
      const fileType = firstFile ? firstFile.type : "image/jpeg";

      const croppedImage = await getCroppedImage(
        stringFile,
        cropArea,
        fileName,
        fileType,
      );

      closeModal();
      removeImage();

      const uploader = new Uploader(
        croppedImage,
        afterUpload,
        formClass,
        inputFieldName,
        uploadProgress,
      );

      uploader.start();
    } catch (error: unknown) {
      console.error(error);
      if (error instanceof Error) {
        setImageError(error.message);
      }
    }
  }, [cropArea]);

  const afterUpload = useCallback(
    (itemUrl: string, signedId: string) => {
      if (attachImage) {
        attachImage(itemUrl, signedId);
      }
      if (saveImage) {
        saveImage(signedId);
      }
    },
    [attachImage, saveImage],
  );

  const removeImage = () => {
    setStringFile(undefined);
    setFileList(undefined);
    setCrop({ x: 0, y: 0 });
    setHasFile(false);
    setEmbedUrl("");
  };

  useEffect(() => {
    if (hasFile && !isCropEnabled && fileList && fileList[0]) {
      closeModal();

      const uploader = new Uploader(
        fileList[0],
        afterUpload,
        formClass,
        inputFieldName,
        uploadProgress,
      );

      uploader.start();
    }
  }, [hasFile, isCropEnabled]);

  return (
    <div className="editor-modal">
      <ModalWrapper
        onClose={closeModal}
        footerActions={{
          onCancel: removeImage,
          onSaveChanges: saveCroppedImage,
        }}
        shouldShowActionsFooter={hasFile && isCropEnabled}
        title={title}
        isOpen={showModal}
      >
        {shouldShowImageCropper && (
          <ImageCropper
            file={stringFile}
            crop={crop}
            onCropComplete={onCropComplete}
            aspectRatio={aspectRatio}
            zoom={zoom}
            setZoom={setZoom}
            setCrop={setCrop}
            imageError={imageError}
          />
        )}
        {!hasFile && (
          <FileUploadMethods
            hideUnsplash={hideUnsplash}
            hideEmbedUrl={hideEmbedUrl}
            unsplashResults={unsplashResults}
            searchUnsplash={setUnsplash}
            addUnsplash={addUnsplash}
            imageError={imageError}
            insertFiles={insertFiles}
            aspectRatioHelpText={aspectRatioHelpText}
            embedUrl={embedUrl}
            onChangeEmbedUrl={onChangeEmbedUrl}
            addEmbed={addEmbed}
          />
        )}
      </ModalWrapper>
    </div>
  );
};
